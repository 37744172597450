import api from '../utils/api'
import omit from 'lodash/omit'
import get from 'lodash/get'

const BASE_URL = 'what-do-we-treat/procedures'

export default {
  get (id) {
    return api.get(`${BASE_URL}/${id}`)
  },
  getAll (params) {
    return api.get(BASE_URL, { params: params })
  },
  update (id, procedure) {
    return api.patch(`${BASE_URL}/${id}`, procedure)
  },
  create (procedure) {
    return api.post(BASE_URL, procedure)
  },
  remove (id) {
    return api.delete(`${BASE_URL}/${id}`)
  },
  copy(uuid) {
    return api.post(`${BASE_URL}/${uuid}/copy`)
  },
  createModel () {
    return {
      status: 'draft', // fixme: use const
      name: null,
      slug: null,
      description: null,
      howToPrepare: null,
      serviceBlockName: null,
      services: [],
      relatedProcedures: [],
      diseases: [],
      photo: null,
      faq: [],
      faqBlockName: null
    }
  },
  toDto (entity) {
    let n = omit(entity, ['photo', 'services', 'relatedProcedures', 'diesases'])
    n.photoId = get(entity, 'photo.id', null)
    n.serviceIds =  entity.services.map(s => s.id)
    n.relatedProcedureIds = entity.relatedProcedures.map(p => p.id)
    n.diseaseIds = entity.diseases.map(d => d.id)
    return n
  },
  getPreviewPath (id) {
    return `/what-do-we-treat/procedures/${id}`
  },
  getPublicPath (slug) {
    return `/what-do-we-treat/procedures/${slug}`
  }
}
